
/* eslint-disable */
import { Options, Vue } from 'vue-class-component';
import 'bulma';

import NavBar from '@/components/NavBar.vue';
import Upload from '@/components/Upload.vue';

@Options({
  components: {
    NavBar,
    Upload,
  },
  data() {
    return {
      data: null,
    };
  },
  methods: {
    dataUpload: function (data: any) {
      let newData = [];
      for (let element in data) {
        console.log(element);
        if (element.includes("revs")) {
          let author = data['globalAuthor:' + data[element].meta.author];
          newData.push({'author': author ? author.name : 'unknown', 'text': data[element].changeset.replace(/^Z:.*\$/i, "")});
        }
      }
      this.data = newData;
    }
  }
})
export default class App extends Vue {}
