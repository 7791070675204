<template>
  <nav class="navbar is-info" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <h1 class="title is-1">EtherPad Log</h1>
    </div>
  </nav>
</template>

<script>
import { Vue } from 'vue-class-component';

export default class NavBar extends Vue {}

</script>

<style scoped>

</style>
